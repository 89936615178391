import * as React from "react";
import { navigate } from "gatsby";
import {Button } from "react-bootstrap";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { TextInput, Textarea, InputWrapper, Group } from "@mantine/core";
import { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import FaMapMarker from "../images/faMapMarker";
import FaTour from "../images/fatour";
import FaBack from "../images/faback";
import useForm from "../components/useForm";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./countrydetails.scss";
import "./tabs.scss";
import HubspotForm from "../components/hubspotcontactform"


export const query = graphql`
query HotelDetialsQuery($slug: String!)  {
    graphCmsItemHotel(slug: { eq: $slug }) {
        slug
        listingTitle
        listingLocation
        listingPriceRange
        listingDescription {                             
            html
            raw
            markdown
            text
        }
        listingPressReleases {
            html
            raw
            markdown
            text
          }
          listingPaymentPlan {
            html
            markdown
            raw
            text
          }
          brochure {
            ctaText
            ctaUrl
            id
          }
          listingVirtualTour {
            ctaText
            ctaUrl
            id
          }
        listingStyle
        listingNumberOfBuildings
        listingPhases
        listingTotalUnits
        listingFloorCount
        listingFinishYear
        listingVideo
        listingFeatureChip {
            id
            featureChip
          }
          listingFeaturesInText {    
            html
            raw
            text
          }
   
        listingImage {
            id
            imageAltText
            imagePhoto {
                gatsbyImageData(placeholder: BLURRED, quality: 85)
                url
            }
        }
        listingGallery {
            id
            imageAltText
            imagePhoto {
                gatsbyImageData(placeholder: BLURRED, quality: 85)
                url
            }
        }
        
        listingBuildStatus
        listingTransportation {
            html
            raw
            text
          }
        listingDescription {
            html
            raw
            text
          }
        listingDisclaimers {
            html
            raw
            text
          }
          listingFaqs {
            id
        listingFaqGroupQuestion
            listingFaq {
              html
              raw
              text
            }
          }
        listingPolicies {
            html
            markdown
            raw
            text
          }


          listing {
            tabTitle
            id
            listingSingleUnits {
              id
              listingSingleUnitBathroomCount
              listingSingleUnitBedroomCount
              listingSingleUnitBuildStatus
              listingSingleUnitFrontViews
              listingSingleUnitFurnishing
              listingSingleUnitIntArea
              listingSingleUnitPrice
              listingSingleUnitRearViews
              listingSingleUnitTitle
              listingSingleUnitExtArea
              listingSingleUnitFloorPlans {
                id
                imageAltText
                imagePhoto {
                  gatsbyImageData(placeholder: BLURRED, quality: 60)
                }
              }
            }
          }
          listingMapPin {
            latitude
            longitude
            remoteTypeName
          }
     
    }
}`;

const HotelDetails = ({ data, index }) => {
  const single = data.graphCmsItemHotel;
  const options = {
    caption: {
      showCaption: false,
    },
  };

  const stateSchema = {
    First_Name: { value: "", error: "" },
    Last_Name: { value: "", error: "" },
    Email: { value: "", error: "" },
  };
  const stateValidatorSchema = {
    First_Name: {
      required: true,
      validator: {
        func: (value) => /(.|\s)*\S(.|\s)*/.test(value),
        error: "Invalid first name format.",
      },
    },
    Last_Name: {
      required: true,
      validator: {
        func: (value) => /^[a-zA-Z]+$/.test(value),
        error: "Invalid last name format.",
      },
    },
    Email: {
      required: true,
      validator: {
        func: (value) => /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/.test(value),
        error: "Invalid email format.",
      },
    },
  };

  function onSubmitForm(state) {}

  const { values, errors, dirty, handleOnChange, handleOnSubmit, disable } =
    useForm(stateSchema, stateValidatorSchema, onSubmitForm);

  const { First_Name, Last_Name, Email } = values;

  const center = {
    lat: data.graphCmsItemHotel.listingMapPin.latitude,
    lng: data.graphCmsItemHotel.listingMapPin.longitude,
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  function Home() {
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: "AIzaSyDdPHthvmxKl6W-UjHtExRRESMejDYyo-Q",
    });

    if (!isLoaded) return <div>Loading....</div>;
    return <Map />;
  }

  function Map() {
    return (
      <div>
        <GoogleMap
          zoom={15}
          center={center}
          //center={`lat:${single.listingMapPin.latitude}, lng:${single.listingMapPin.longitude}`}
          mapContainerClassName="containerStyle"
        >
          <Marker position={center} />
        </GoogleMap>
      </div>
    );
  }

  const mouseClickEvents = ["mousedown", "click", "mouseup"];
  useEffect(() => {
    function simulateMouseClick(element) {
      mouseClickEvents.forEach((mouseEventType) =>
        element.dispatchEvent(
          new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1,
          })
        )
      );
    }
    if (document.querySelector(".tab-main input:first-child")) {
      var element1 = document.querySelector(".tab-main input:first-child");
      simulateMouseClick(element1);
    }
    if (document.querySelector(".tab-main input:first-child")) {
      var element1 = document.querySelector(".tab-main input:first-child");
      simulateMouseClick(element1);
    }
    if (!document.querySelector(".faq-main .faq-content")) {
      document.querySelector(".faq").style.display = "none";
    }
    if (!document.querySelector(".unit-types .mytabs .tab-main")) {
      document.querySelector(".unit-types").style.display = "none";
    }
    if (!document.querySelector("#data-table table tbody tr")) {
      document.querySelector("#data-table").style.display = "none";
    }
  });

  return (
    <Layout>
      <Seo
        title={single.listingTitle + " | Royal Palm Realty & Travel"}
        description={single.listingDescription.text?.substring(0, 155) || " "}
        image={single.listingImage.imagePhoto.url}
      />
      <Helmet>
        <meta
          property="og:image"
          content={single.listingImage.imagePhoto.url}
        />
        <meta
          property="twitter:image"
          content={single.listingImage.imagePhoto.url}
        />
      </Helmet>

      <div className="listing-details">
        <div className="main-details">
          <div className="row">
            <div className="col-sm-7">
              <ul div className="hero-buttons">
                <li class="back">
                  <a>
                    <Button id="button-two" onClick={() => navigate(-1)}>
                      <FaBack />
                    </Button>
                  </a>
                </li>

                {single.listingVirtualTour && (
                  <li>
                    <a
                      href={`${single.listingVirtualTour.ctaUrl}`}
                      target="_blank"
                    >
                      <button
                        type="button"
                        id="button-two"
                        class="btn btn-primary"
                      >
                        <FaTour />
                        Virtual Tour
                      </button>
                    </a>
                  </li>
                )}
              </ul>

              <div className="listing-gallery">
                <Carousel options={options}>
                  {(single.listingGallery || []).map((imagesMap, id) => (
                    <>
                      <GatsbyImage
                        image={imagesMap.imagePhoto.gatsbyImageData}
                        quality={60}
                        key={imagesMap.id}
                        formats={["auto", "webp", "avif"]}
                        alt={imagesMap.imageAltText}
                      />

                      <img
                        src={imagesMap.imagePhoto.url}
                        style={{ position: "absolute" }}
                      />
                    </>
                  ))}
                </Carousel>
              </div>
            </div>

            <div className="col-sm-5">
              <div className="listing-info">
                <div className="header-content">
                  <h1>{single.listingTitle}</h1>
                  <span className="location">
                    <FaMapMarker /> {single.listingLocation}
                  </span>
                  <br />
                  {single.listingPriceRange && (
                    <span className="price">
                      <h4>{single.listingPriceRange.replace(/_/g, " ")}</h4>
                    </span>
                  )}
{single.brochure && (
<span className="price" id="brochure-btn">
    <h4 data-toggle="modal" data-target="#myModal">
    See Brochure
    </h4>

    <div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h2>Request Brochure</h2>
            <button
            type="button"
            class="close"
            data-dismiss="modal"
            >
            &times;
            </button>
        </div>
        <div class="modal-body">
            <p>
            Please fill in the information below to receive
            the brochure on {single.listingTitle}, and
            you'll be connected to a representative for any
            further inquiries.
            </p>
            <div className="contact">
            <a id="contact-us" title="Contact Us"></a>
            <div className="wrapper">
                <div
                className="contact-form zcwf_lblLeft crmWebToEntityForm"
                id="crmWebToEntityForm"
                >
                <form
                    action="https://crm.zoho.com/crm/WebToContactForm"
                    name="WebToLeads5284090000006653037"
                    method="POST"
                    onSubmit='javascript:document.charset="UTF-8"; return checkMandatory5284090000006653037()'
                    accept-charset="UTF-8"
                >
                    <div
                    class="pre-defined"
                    style={{
                        display: "none",
                        visibility: "hidden",
                    }}
                    >
                    <input
                        type="text"
                        style={{ display: "none" }}
                        name="xnQsjsdp"
                        value="37aa81dbb31044d2bc1fc33e4f9dd748ae6e8eeacb7f34cee46f53b5ba68d277"
                    ></input>
                    <input
                        type="hidden"
                        name="zc_gad"
                        id="zc_gad"
                        value=""
                    ></input>
                    <input
                        type="text"
                        style={{ display: "none" }}
                        name="xmIwtLD"
                        value="fa4b9c59c07f0bfbb34865f563999f60ce589d958b8e59df82d2b3873f7946ab"
                    ></input>
                    <input
                        type="text"
                        style={{ display: "none" }}
                        name="actionType"
                        value="TGVhZHM="
                    ></input>
                    <input
                        type="text"
                        name="returnURL"
                        value="https://royalpalmdestinations.co/thank-you"
                    />
                    {/*
                                <!-- Do not remove this code. -->*/}
                    <input
                        type="text"
                        id="ldeskuid"
                        name="ldeskuid"
                    ></input>
                    <input
                        type="text"
                        id="LDTuvid"
                        name="LDTuvid"
                    ></input>{" "}
                    {/*
                                <!-- Do not remove this code. -->*/}
                    </div>
                    <InputWrapper id="name" required>
                    <Group grow>
                        <div className="split">
                        <TextInput
                            placeholder="First Name"
                            id="First_Name"
                            name="First Name"
                            maxLength="40"
                            variant="filled"
                            defaultValue={First_Name}
                            onChange={handleOnChange}
                        />{" "}
                        {errors.First_Name &&
                            dirty.First_Name && (
                            <p className="error">
                                {errors.First_Name}
                            </p>
                            )}
                        </div>
                        <div className="split">
                        <TextInput
                            placeholder="Last Name"
                            id="Last_Name"
                            name="Last Name"
                            maxLength="80"
                            variant="filled"
                            defaultValue={Last_Name}
                            onChange={handleOnChange}
                        />{" "}
                        {errors.Last_Name &&
                            dirty.Last_Name && (
                            <p className="error">
                                {errors.Last_Name}
                            </p>
                            )}
                        </div>
                    </Group>
                    </InputWrapper>
                    <InputWrapper id="email" required>
                    <TextInput
                        placeholder="Your Email Address"
                        ftype="email"
                        name="Email"
                        id="Email"
                        defaultValue={Email}
                        onChange={handleOnChange}
                        maxLength="100"
                        variant="filled"
                    />{" "}
                    {errors.Email && dirty.Email && (
                        <p className="error">
                        {errors.Email}
                        </p>
                    )}
                    </InputWrapper>
                    <InputWrapper id="phone-number" required>
                    <TextInput
                        placeholder="Phone"
                        id="Phone"
                        name="Phone"
                        maxLength="50"
                        variant="filled"
                    />
                    </InputWrapper>
                    <InputWrapper id='Company'>
                    <TextInput
                        placeholder='Company'
                        name='Company'
                        variant="filled"
                        id='Company'
                        maxLength="200"
                    />
                    </InputWrapper>
                    <select
                    className="form-select form-control shadow-none"
                    type="interest"
                    id="LEADCF10"
                    name="LEADCF10"
                    aria-label="Default select example"
                    >
                    <option style={{ display: "none" }}>
                        I am interesed in... (Choose one)
                    </option>
                    <option
                        value={`${single.listingTitle}`}
                        selected
                    >
                        {single.listingTitle}
                    </option>
                    </select>
                    <div className="shrink">
                    <select
                        className="form-select form-control shadow-none"
                        id="LEADCF11"
                        name="Webform Input"
                    >
                        <option value="Royal&#x20;Palm&#x20;Brochure&#x20;Form" selected>Royal Palm Brochure</option>
                    </select>
                    <select
                        className="form-select form-control shadow-none"
                        type="brochure"
                        for="LEADCF12"
                        id="LEADCF12"
                        name="LEADCF12"
                        aria-label="Default selection"
                    >
                        <option value={`${single.brochure.ctaUrl}`}>{single.brochure.ctaUrl}</option>
                    </select>
                    </div>
                    <InputWrapper id="message">
                    <Textarea
                        placeholder="Message..."
                        variant="filled"
                        id="Description"
                        name="listing brochure requested"
                        minRows={3}
                        maxRows={6}
                        value={`${single.listingTitle}`}
                    />
                    </InputWrapper>

                    <InputWrapper id="submit">
                    <Button
                        id="button-one"
                        type="submit"
                        class="formsubmit zcwf_button"
                        htmlType="submit"
                        value="Submit"
                        title="Submit"
                        disabled={disable}
                    >
                        Send
                    </Button>
                    </InputWrapper>
                    <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                        __html: `
                            function validateEmail5284090000002116011()
                            {
                                var form = document.forms['WebToLeads5284090000002116011'];
                                var emailFld = form.querySelectorAll('[ftype=email]');
                                var i;
                                for (i = 0; i < emailFld.length; i++)
                                {
                                    var emailVal = emailFld[i].value;
                                    if((emailVal.replace(/^\s+|\s+$/g, '')).length!=0 )
                                    {
                                        var atpos=emailVal.indexOf('@');
                                        var dotpos=emailVal.lastIndexOf('.');
                                        if (atpos
                        <1 || dotpos
                            <atpos+2 || dotpos+2>=emailVal.length)
                                        {
                                            alert('Please enter a valid email address. ');
                                            emailFld[i].focus();
                                            return false;
                                        }
                                    }
                                }
                                return true;
                            }
                        
                                function checkMandatory5284090000002116011() {
                                var mndFileds = new Array('First Name','Last Name','Email');
                                var fldLangVal = new Array('First\x20Name','Last\x20Name','Email');
                                for(i=0;i
                                <mndFileds.length;i++) {
                                    var fieldObj=document.forms['WebToLeads5284090000002116011'][mndFileds[i]];
                                    if(fieldObj) {
                                    if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length==0) {
                                    if(fieldObj.type =='file')
                                        { 
                                        alert('Please select a file to upload.'); 
                                        fieldObj.focus(); 
                                        return false;
                                        } 
                                    alert(fldLangVal[i] +' cannot be empty.'); 
                                                fieldObj.focus();
                                                return false;
                                    }  else if(fieldObj.nodeName=='SELECT') {
                                            if(fieldObj.options[fieldObj.selectedIndex].value=='-None-') {
                                        alert(fldLangVal[i] +' cannot be none.'); 
                                        fieldObj.focus();
                                        return false;
                                        }
                                    } else if(fieldObj.type =='checkbox'){
                                        if(fieldObj.checked == false){
                                        alert('Please accept  '+fldLangVal[i]);
                                        fieldObj.focus();
                                        return false;
                                        } 
                                    } 
                                    try {
                                        if(fieldObj.name == 'Last Name') {
                                        name = fieldObj.value;
                                            }
                                    } catch (e) {}
                                    }
                                }
                                trackVisitor5284090000002116011();
                                if(!validateEmail5284090000002116011()){return false;}
                                document.querySelector('.crmWebToEntityForm .formsubmit').setAttribute('disabled', true);
                            }
                        
                        function tooltipShow5284090000002116011(el){
                            var tooltip = el.nextElementSibling;
                            var tooltipDisplay = tooltip.style.display;
                            if(tooltipDisplay == 'none'){
                                var allTooltip = document.getElementsByClassName('zcwf_tooltip_over');
                                for(i=0; i
                                    <allTooltip.length; i++){
                                    allTooltip[i].style.display='none';
                                }
                                tooltip.style.display = 'block';
                            }else{
                                tooltip.style.display='none';
                            }
                        }`,
                    }}
                    ></script>
                    <script
                    type="text/javascript"
                    id="VisitorTracking"
                    dangerouslySetInnerHTML={{
                        __html: `
                        var $zoho= $zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode:'dc4ce78d739b855a8b9a4a6d56609edcd7296f89ba434dbde57fcde0c8ac6736ca25e4b6c66ef62ba11f7950fb6f1aed', values:{},ready:function(){}};var d=document;s=d.createElement('script');s.type='text/javascript' ;s.id='zsiqscript' ;s.defer=true;s.src='https://salesiq.zoho.com/widget' ;t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);function trackVisitor5284090000002116011(){try{if($zoho){var LDTuvidObj=document.forms['WebToLeads5284090000002116011']['LDTuvid'];if(LDTuvidObj){LDTuvidObj.value=$zoho.salesiq.visitor.uniqueid();}var firstnameObj=document.forms['WebToLeads5284090000002116011']['First Name'];if(firstnameObj){name=firstnameObj.value +' '+name;}$zoho.salesiq.visitor.name(name);var emailObj = document.forms[' WebToLeads5284090000002116011']['Email'];if(emailObj){email=emailObj.value;$zoho.salesiq.visitor.email(email);}}} catch(e){}} `,
                    }}
                    ></script>
                    <script
                    type="text/javascript"
                    id="wf_anal"
                    src="zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=fa4b9c59c07f0bfbb34865f563999f60ef2f244e1f3e38270800c1447546f2degid37aa81dbb31044d2bc1fc33e4f9dd748ae6e8eeacb7f34cee46f53b5ba68d277gid885e3c1045bd9bdcc91bdf30f82b5696gid14f4ec16431e0686150daa43f3210513&tw=61690b96c1d0471b638f31426f38e68aa67fb7ed6da86f32dc10ad817fe55a0a"
                    ></script>
                </form>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</span>
)}

                  <ul className="data">
                    {single.listingStyle && (
                      <li>
                        <span>Style</span>
                        <br />
                        {single.listingStyle.replace(/_/g, " ")}
                      </li>
                    )}
                    {single.listingBuildStatus && (
                      <li>
                        <span>Status</span>
                        <br />
                        {single.listingBuildStatus.replace(/_/g, " ")}
                      </li>
                    )}
                    {single.listingFloorCount && (
                      <li>
                        <span>Floors</span>
                        <br />
                        {single.listingFloorCount}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="sticky-header">
                <ul className="sticky-content">
                  <li>
                    <a href="#data-table">Key Notes</a>
                  </li>
                  <li>
                    <a href="#feature-chips">Description</a>
                  </li>
                  <li>
                    <a href="#desc">Location</a>
                  </li>
                  <li>
                    <a href="#location">Features</a>
                  </li>
                  <li>
                    <a href="#contact-us">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="secondary-info">
                <div id="data-table" className="info-table">
                  <table>
                    <tbody>
                      {single.listingTotalUnits && (
                        <tr>
                          <td>Total Units</td>
                          <td id="main-1">{single.listingTotalUnits}</td>
                        </tr>
                      )}
                      {single.listingFloorCount && (
                        <tr>
                          <td>Floor Count</td>
                          <td id="main-1">{single.listingFloorCount}</td>
                        </tr>
                      )}
                      {single.listingNumberOfBuildings && (
                        <tr>
                          <td>No. of Buildings</td>
                          <td id="main-1">{single.listingNumberOfBuildings}</td>
                        </tr>
                      )}
                      {single.listingPhases && (
                        <tr>
                          <td>Listing Phases</td>
                          <td id="main-1">{single.listingPhases}</td>
                        </tr>
                      )}
                      {single.listingFinishYear && (
                        <tr>
                          <td>Year of Completion</td>
                          <td id="main-1">{single.listingFinishYear}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div id="feature-chips" className="feature-chips">
                  <ul className="features-main">
                    {(single.listingFeatureChip || []).map((features, id) => (
                      <li key={id}>{features.featureChip}</li>
                    ))}
                  </ul>
                </div>
                {single.listingDescription && (
                  <div id="desc" className="description">
                    <div className="desc-main">
                      <h2>Description</h2>
                      {single.listingDescription.text}
                    </div>
                  </div>
                )}
                <div id="location" className="map">
                  <Home />
                </div>
                {single.listingFeaturesInText && (
                  <div id="features" className="features">
                    <div className="features-text">
                      <h2>Features</h2>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: single.listingFeaturesInText.html,
                        }}
                      />
                    </div>
                  </div>
                )}
                {single.listing && (
                  <div className="unit-types">
                    <h2 id="title">Unit Types</h2>
                    <div class="mytabs">
                      {(single.listing || []).map((listingStyle, id) => (
                        <div className="tab-main">
                          <input
                            type="radio"
                            id={`${listingStyle.id}`}
                            name="mytabs"
                          ></input>
                          <label htmlFor={`${listingStyle.id}`}>
                            <h2>{listingStyle.tabTitle.replace(/_/g, " ")}</h2>
                          </label>
                          <div class="tab">
                            {(listingStyle.listingSingleUnits || []).map(
                              (listingSingle, id) => (
                                <div className="tab-content">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      {(
                                        listingSingle.listingSingleUnitFloorPlans ||
                                        []
                                      ).map((floorPlanMap, id) => (
                                        <div className="floorplan-img">
                                          <GatsbyImage
                                            image={
                                              floorPlanMap.imagePhoto
                                                .gatsbyImageData
                                            }
                                            quality={60}
                                            formats={["auto", "webp", "avif"]}
                                            alt={floorPlanMap.imageAltText}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                    <div className="col-sm-8">
                                      <h4 id="title-secondary">
                                        {listingSingle.listingSingleUnitTitle} -{" "}
                                        {listingSingle.listingSingleUnitPrice}
                                      </h4>
                                      <h4>
                                        <strong>
                                          {
                                            listingSingle.listingSingleUnitBedroomCount
                                          }{" "}
                                          Bedroom,{" "}
                                          {
                                            listingSingle.listingSingleUnitBathroomCount
                                          }{" "}
                                          Bathroom
                                        </strong>
                                      </h4>
                                      <h4>
                                        <strong>
                                          Interior{" "}
                                          {
                                            listingSingle.listingSingleUnitIntArea
                                          }
                                          <sup>2</sup> | Exterior{" "}
                                          {
                                            listingSingle.listingSingleUnitExtArea
                                          }
                                          <sup>2</sup>
                                        </strong>
                                      </h4>
                                      <p>
                                        <table>
                                          <tbody>
                                            <tr>
                                              <td id="main">Front Views</td>
                                              <td>
                                                {listingSingle.listingSingleUnitFrontViews.replace(
                                                  /_/g,
                                                  " "
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td id="main">Rear Views</td>
                                              <td>
                                                {listingSingle.listingSingleUnitRearViews.replace(
                                                  /_/g,
                                                  " "
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td id="main">Furnished</td>
                                              <td>
                                                {listingSingle.listingSingleUnitFurnishing.replace(
                                                  /_/g,
                                                  " "
                                                )}
                                              </td>
                                            </tr>
                                            {listingSingle.listingSingleUnitBuildStatus && (
                                              <tr>
                                                <td id="main">Build Status</td>
                                                <td>
                                                  {listingSingle.listingSingleUnitBuildStatus.replace(
                                                    /_/g,
                                                    " "
                                                  )}
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {single.listingVideo && (
                  <div className="featured-video">
                    <iframe
                      width="100%"
                      height="300px"
                      src={single.listingVideo}
                      title="Featured Video"
                      frameborder="0"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                )}
                {single.listingPressReleases && (
                  <div className="disclaimer">
                    <div className="disclaimer-main">
                      <h2>Press Release</h2>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: single.listingPressReleases.html,
                        }}
                      />
                    </div>
                  </div>
                )}
                {single.listingTransportation && (
                  <div className="transportation">
                    <div className="featured-transportation">
                      <h2>Transportation</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: single.listingTransportation.html,
                        }}
                      />
                    </div>
                  </div>
                )}
                {single.listingFaqs && (
                  <div className="faq">
                    <h2>Frequently Asked Questions</h2>
                    <div className="faq-main">
                      {(single.listingFaqs || []).map((faq, id) => (
                        <div className="faq-content">
                          <strong>{faq.listingFaqGroupQuestion}</strong>
                          <p>{faq.listingFaq.text}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {single.listingPolicies && (
                  <div className="policies">
                    <div className="policies-main">
                      <h2>Policies</h2>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: single.listingPolicies.html,
                        }}
                      />
                    </div>
                  </div>
                )}
                {single.listingDisclaimers && (
                  <div className="disclaimer">
                    <div className="disclaimer-main">
                      <h2>Disclaimers</h2>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: single.listingDisclaimers.html,
                        }}
                      />
                    </div>
                  </div>
                )}
                {single.listingPaymentPlan && (
                  <div className="disclaimer">
                    <div className="disclaimer-main">
                      <h2>Payment Plans</h2>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: single.listingPaymentPlan.html,
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="contact-block" id="contact-us">
                  <div className="contact-form">
                    <div className="wrapper">
                      <h2>Contact Us</h2>
                      <HubspotForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HotelDetails;
